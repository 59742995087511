import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  CircularProgress,
  Autocomplete,
  Snackbar,
  Switch,
} from "@mui/material";

import {
  Delete as DeleteIcon,
  Search as SearchIcon,
  CameraAlt as CameraAltIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BsImages } from "react-icons/bs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";

import {
  getPlatform,
  // getNIE,
  getNIEElasticSearch,
  getNieDetail,
  getGroups,
  getPrincipal,
  getPackaging,
  createProduct,
  uploadImage,
  deleteImage,
  getCategoryMaster,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { EMPTY_IMG_SRC, IGNORE_TYPE } from "../../../constants/constant";
import PopupSingleCalendar from "../../../common/particles.jsx/PopupSingleCalendar";
import ErrorPopup from "../../../common/particles.jsx/ErrorPopup";

const FETCH_LIMIT = 10;

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const AddProduct = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [search, setSearch] = useState("");
  const [nieNumber, setNieNumber] = useState("");
  const [nieId, setNieId] = useState("");
  const [bpomId, setBpomId] = useState("");
  const [open, setOpen] = useState(false);
  const [openPrincipal, setOpenPrincipal] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [optionPrincipal, setOptionPrincipal] = useState([]);
  const [optionCategory, setOptionCategory] = useState([]);
  const [principalValue, setPrincipalValue] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [isCreateProductWithNIE, setIsCreateProductWithNIE] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [nie, setNie] = useState("");
  const [selectedBatch, setSelectedBatch] = useState(-1);
  const [openSingleCal, setOpenSingleCal] = useState(false);
  const [deleteImg, setDeleteImg] = useState(null);

  const debounce = useDebounce(search, 500);

  const debouncePrincipal = useDebounce(searchPrincipal, 500);

  const debounceCategory = useDebounce(searchCategory, 500);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    keepPreviousData: true,
    queryFn: () => getPlatform(),
  });

  const { isFetching: isNieLoading } = useQuery({
    queryKey: ["nie", debounce],
    queryFn: () => getNIEElasticSearch(debounce, FETCH_LIMIT, 1),
    keepPreviousData: true,
    onSuccess: (data) => setNie(data.data),
    enabled: open,
  });

  const {
    data,
    isFetching,
    refetch: refetchDetail,
  } = useQuery({
    queryKey: ["nie_detail"],
    queryFn: () => getNieDetail(nieId, bpomId),
    // keepPreviousData: true,
    // enabled: !!nieId && !!bpomId,
    // enabled: bpomId !== undefined && bpomId !== null,
    enabled: false,
    onSuccess: (data) => {
      setCategoryValue(
        data &&
          data !== undefined &&
          data?.category_id !== null &&
          data?.category_id?.length > 1 &&
          data?.category_id[0] !== 0
          ? data.category_id?.map((id, index) => ({
              id,
              name: data.category_name[index],
            }))
          : []
      );
      setPrincipalValue({
        id: data && data !== undefined ? data.principal_id : "",
        name: data && data !== undefined ? data.principal_name : "",
      });
    },
  });

  useEffect(() => {
    if (bpomId && bpomId !== null) refetchDetail();
  }, [bpomId]);

  const { data: packaging } = useQuery({
    queryKey: ["packaging", data],
    queryFn: () => getPackaging(),
    keepPreviousData: true,
  });

  const { data: categories, isFetching: isCategoryLoading } = useQuery({
    queryKey: ["category", data, debounceCategory],
    queryFn: () => getCategoryMaster(FETCH_LIMIT, 1, debounceCategory),
  });

  const { data: principals, isFetching: isPrincipalLoading } = useQuery({
    queryKey: ["principal", data, debouncePrincipal],
    queryFn: () => getPrincipal(FETCH_LIMIT, 1, debouncePrincipal),
  });

  const { data: groups } = useQuery({
    queryKey: ["groups", data],
    queryFn: () => getGroups(),
  });

  const { mutate: mutateCreateProduct } = useMutation({
    mutationFn: createProduct,
    onSuccess: () =>
      navigate(
        `${isWarehouse ? "/central-warehouse" : ""}/inventory/produk-aktif`
      ),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateUploadImage, isLoading: isLoadingUpload } = useMutation(
    {
      mutationFn: uploadImage,
      onSuccess: (data) => {
        formik.setFieldValue("product_image", [
          ...formik.values.product_image,
          data,
        ]);
      },
      onError: (err) =>
        setErrorMessage(err.message.id ? err.message.id : err.message),
    }
  );

  const { mutate: mutateDeleteImage, isLoading: isLoadingDelete } = useMutation(
    {
      mutationFn: deleteImage,
      onSuccess: () => {
    
        formik.setFieldValue(
          "product_image",
          deleteImg < 0
            ? []
            : [
                ...formik.values.product_image.slice(0, deleteImg),
                ...formik.values.product_image.slice(deleteImg + 1),
              ]
        );
      },
      onError: (err) =>
        setErrorMessage(err.message.id ? err.message.id : err.message),
    }
  );

  const formik = useFormik({
    initialValues: {
      warehouse: isWarehouse ? isWarehouse : false,
      nie_number:
        data && data !== undefined && isCreateProductWithNIE
          ? data.nie_number
          : search,
      principal_id:
        data && data !== undefined && isCreateProductWithNIE
          ? data.principal_id
          : "",
      category_id:
        data &&
        data !== undefined &&
        data?.category_id?.length > 1 &&
        data?.category_id[0] !== 0 &&
        isCreateProductWithNIE
          ? data.category_id
          : null,
      label_id:
        data &&
        data !== undefined &&
        data?.label_id !== 0 &&
        isCreateProductWithNIE
          ? data.label_id
          : "",
      product_name:
        data && data !== undefined && isCreateProductWithNIE
          ? data.product_name
          : "",
      product_image:
        data && data !== undefined && isCreateProductWithNIE ? data.media : [],
      product_description: {
        indication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.indication !== null
            ? data.indication
            : "",
        composition:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.usage !== null
            ? data.composition
            : "",
        dosage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.dosage !== null
            ? data.dosage
            : "-",
        usage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data.usage !== null
            ? data.usage
            : "-",
        contraindication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.contraindication !== null
            ? data.contraindication
            : "",
        side_effect:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.side_effect !== null
            ? data.side_effect
            : "",
        storage_description:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.storage_description !== null
            ? data.storage_description
            : "",
      },
      shelf: "",
      require_prescription:
        data && data !== undefined && isCreateProductWithNIE
          ? data.require_prescription
          : false,
      packaging: {
        type_box:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.premier.replace(/\d+/g, "").trim()
            : "",

        total_box: 1,

        type_strip:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.skunder.replace(/\d+/g, "").trim()
            : "",

        total_strip:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.skunder) &&
          isCreateProductWithNIE
            ? Number(data.packaging?.skunder.match(/\d/g).join(""))
            : 1,

        type_unit:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.tersier
                .replace(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*/g, "")
                .trim()
            : "",

        total_unit:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.tersier) &&
          isCreateProductWithNIE
            ? parseInt(data.packaging?.tersier.match(/\d+/).join(""), 10)
            : 1,
      },
      price: {
        platform_price: [],
        pharmacy_net_price: 0,
        pharmacy_net_price_discount: 0,
        pharmacy_net_price_ppn: false,
        pharmacy_net_price_real: 0,
        sell_price: 0,
        sell_price_sekunder: 0,
        sell_price_tersier: 0,
        price_grosir: [{ minimum: 0, maximum: 0, price_grosirs: 0 }],
      },
      reminder_stock: 5,
      status_reminder_stock: true,
      reminder_uom: 0,
      batch: [{ quantity: 0, number_batch: "", expired_date: "" }],
      barcode_number: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateCreateProduct({
        ...values,
        product_image:
          values.product_image.length === 0
            ? [EMPTY_IMG_SRC]
            : values.product_image,
        price: {
          ...values.price,
          pharmacy_net_price: pharmacyNetPriceFinal(
            values.price.pharmacy_net_price_discount,
            values.price.pharmacy_net_price_real,
            values.price.pharmacy_net_price_ppn
          ),
        },
        product_description: {
          indication:
            values.product_description.indication === ""
              ? "-"
              : values.product_description.indication,
          composition:
            values.product_description.composition === ""
              ? "-"
              : values.product_description.composition,
          dosage:
            values.product_description.dosage === ""
              ? "-"
              : values.product_description.dosage,
          usage:
            values.product_description.usage === ""
              ? "-"
              : values.product_description.usage,
          contraindication:
            values.product_description.contraindication === ""
              ? "-"
              : values.product_description.contraindication,
          side_effect:
            values.product_description.side_effect === ""
              ? "-"
              : values.product_description.side_effect,
          storage_description:
            values.product_description.storage_description === ""
              ? "-"
              : values.product_description.storage_description,
        },
        batch: values.batch.map((data) => {
          if (qtyType === values.packaging.type_unit) {
            return {
              quantity: data.quantity,
              number_batch: data.number_batch,
              expired_date: data.expired_date,
            };
          }
          if (qtyType === values.packaging.type_strip) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity: data.quantity * values.packaging.total_unit,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          if (qtyType === values.packaging.type_box) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * values.packaging.total_strip * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity:
                  data.quantity *
                  values.packaging.total_strip *
                  values.packaging.total_unit *
                  1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          return {
            quantity: data.quantity,
            number_batch: data.number_batch,
            expired_date: data.expired_date,
          };
        }),
      });
    },
  });

  const intersection = new Set(
    formik.values.price.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined && nieNumber)
      formik.setFieldValue("price.platform_price", [
        ...formik.values.price.platform_price,
        {
          platform_id: result[0]?.id,
          price: "",
        },
      ]);
    else formik.setFieldValue("price.platform_price", []);
  }, [formik.values.price.platform_price, result]);

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    isTaxEnabled
  ) => {
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * 0.11;
    if (!isTaxEnabled) {
      value = 0;
    }
    return pharmacyNetPriceReal - discount + value;
  };

  const finalStock = useMemo(() => {
    if (qtyType === formik.values.packaging.type_unit) {
      return count;
    }
    if (qtyType === formik.values.packaging.type_strip) {
      if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
        return count;
      } else {
        return count * formik.values.packaging.total_unit;
      }
    }
    if (qtyType === formik.values.packaging.type_box) {
      if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
        return count * formik.values.packaging.total_strip;
      } else {
        return (
          count *
          formik.values.packaging.total_unit *
          formik.values.packaging.total_strip
        );
      }
    }
    return 0;
  }, [count, formik, qtyType]);

  const calculateBatchStock = useCallback(
    (e, index, b) => {
      let numericValue = e.target.value;
      if (numericValue === "") {
        numericValue = "0";
      }
      if (qtyType === formik.values.packaging.type_unit) {
        return formik.setFieldValue(
          "batch",
          formik.values.batch.map((b1, idx) => {
            return index === idx
              ? {
                  ...b,
                  quantity: parseInt(numericValue.replace(/[^0-9]/g, ""), 10),
                }
              : b1;
          })
        );
      }
      if (qtyType === formik.values.packaging.type_strip) {
        if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) * 1,
                  }
                : b1;
            })
          );
        } else {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      formik.values.packaging.total_unit,
                  }
                : b1;
            })
          );
        }
      }
      if (qtyType === formik.values.packaging.type_box) {
        if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      1 *
                      formik.values.packaging.total_strip,
                  }
                : b1;
            })
          );
        } else {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      1 *
                      formik.values.packaging.total_unit *
                      formik.values.packaging.total_strip,
                  }
                : b1;
            })
          );
        }
      }
    },
    [formik, qtyType]
  );

  // const handleDeleteImage = useCallback(
  //   (index, value) => {
  //     // formik.setFieldValue("product_id", [
  //     //   ...formik.values.product_image.splice(index, 1),
  //     // ]);
  //     setDeleteImg(value);
  //     mutateDeleteImage(value);
  //   },
  //   [formik.values.product_image]
  // );

  useEffect(() => {
    if (openCategory)
      setOptionCategory(categories && categories !== null ? categories : []);
    if (openPrincipal)
      setOptionPrincipal(principals !== undefined ? principals : []);
  }, [categories, principals, openCategory, openPrincipal]);

  useEffect(() => {
    if (debounce === "") setNie([]);
  }, [debounce]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Tambah Produk
      </h1>
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() =>
            navigate(
              `${
                isWarehouse ? "/central-warehouse" : ""
              }/inventory/produk-aktif`
            )
          }
        >
          Produk Aktif
        </p>
        <p>/</p>
        <p>Tambah Produk</p>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-col-1 shadow-md rounded-lg mt-5 p-3">
          <div
            className="cursor-pointer p-4 border border-black rounded-md"
            onClick={() => {
              setOpen(true);
              setSearch("");
            }}
          >
            {nieNumber !== "" ? <p>{nieNumber}</p> : <p>Nomor Izin Edar</p>}
          </div>
          {(nieNumber !== "" && !isFetching) || nieNumber === "" ? (
            <>
              <div className="input-foto">
                <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                  <span>
                    <BsImages />
                  </span>
                  Foto Produk
                </div>
                <div className="flex items-center justify-center h-64 border-2 border-dashed">
                  {isLoadingUpload || isLoadingDelete ? (
                    <CircularProgress size={20} className="my-4" />
                  ) : (
                    <>
                      {(nieNumber === ""
                        ? []
                        : formik.values.product_image.length === 0
                        ? [EMPTY_IMG_SRC]
                        : formik.values.product_image
                      ).map((value, index) => (
                        <div
                          className="flex items-start justify-center w-[25%] mx-[10px]"
                          key={index}
                        >
                          <img
                            alt=""
                            className="rounded-md h-[50%] w-[50%]"
                            src={value}
                          />
                          {formik.values.product_image.length === 0 ? null : (
                            <button
                              type="button"
                              onClick={() => {
                                // handleDeleteImage(index, value);
                                mutateDeleteImage(value);
                                setDeleteImg(index);
                              }}
                            >
                              <ClearIcon />
                            </button>
                          )}
                        </div>
                      ))}
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-col items-center justify-center w-[15%] p-6 border-2 rounded-md"
                      >
                        <input
                          id="dropzone-file1"
                          hidden
                          type="file"
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={(e) => {
                            if (
                              formik.values.product_image.length >= 3 ||
                              nieNumber === ""
                            ) {
                              return;
                            }
                            mutateUploadImage(e.target.files[0]);
                          }}
                        />
                        <div className="flex justify-center flex-col text-center">
                          <CameraAltIcon
                            fontSize="large"
                            sx={{ color: "black" }}
                          />
                          <p>
                            {formik.values.product_image.length === 0 &&
                            nieNumber !== ""
                              ? 1
                              : formik.values.product_image.length}
                            /3
                          </p>
                        </div>
                      </label>
                    </>
                  )}
                </div>
              </div>
              <TextField
                id="outlined-basic"
                label="Nama product"
                name="product_name"
                variant="outlined"
                className="mt-3"
                disabled={nieNumber === ""}
                value={formik.values.product_name}
                onChange={(e) =>
                  formik.setFieldValue("product_name", e.target.value)
                }
              />
              <Autocomplete
                fullWidth
                multiple
                disabled={nieNumber === ""}
                loading={isCategoryLoading}
                sx={{ marginTop: 3 }}
                open={openCategory}
                onOpen={() => setOpenCategory(true)}
                onClose={() => setOpenCategory(false)}
                disablePortal
                options={optionCategory}
                value={
                  categoryValue && categoryValue !== "" ? categoryValue : null
                }
                onChange={(_, inputValue) => {
                  setCategoryValue(inputValue);
                  setOptionCategory([]);
                  formik.setFieldValue(
                    "category_id",
                    inputValue !== null ? inputValue.map((val) => val.id) : []
                  );
                }}
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearchCategory(onInputValue);
                    setOptionCategory([]);
                  }
                }}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tambah Kategori"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <TextField
                name="indication"
                disabled={nieNumber === ""}
                label="Indikasi / Kegunaan"
                variant="outlined"
                className="mt-3"
                value={formik.values.product_description.indication}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.indication",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-basic"
                name="composition"
                disabled={nieNumber === ""}
                label="Kandungan / Komposisi"
                variant="outlined"
                className="mt-3"
                value={formik.values.product_description.composition}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.composition",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-multiline-static"
                name="dosage"
                label="Dosis"
                className="mt-3"
                disabled={nieNumber === ""}
                value={formik.values.product_description.dosage}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.dosage",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-multiline-static"
                name="usage"
                label="Cara Pemakaian"
                multiline
                disabled={nieNumber === ""}
                rows={2}
                className="mt-3"
                value={formik.values.product_description.usage}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.usage",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-basic"
                name="containdication"
                label="Kontradiksi / Jangan digunakan oleh"
                disabled={nieNumber === ""}
                variant="outlined"
                className="mt-3"
                value={formik.values.product_description.contraindication}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.contraindication",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-basic"
                label="Efek Samping"
                name="side_effect"
                multiline
                disabled={nieNumber === ""}
                rows={3}
                variant="outlined"
                className="mt-3"
                value={formik.values.product_description.side_effect}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.side_effect",
                    e.target.value
                  )
                }
              />
              <TextField
                id="outlined-basic"
                label="Cara Penyimpanan"
                name="storage_description"
                variant="outlined"
                className="mt-3"
                disabled={nieNumber === ""}
                value={formik.values.product_description.storage_description}
                onChange={(e) =>
                  formik.setFieldValue(
                    "product_description.storage_description",
                    e.target.value
                  )
                }
              />
              <FormControl fullWidth className="mt-3">
                <InputLabel id="demo-simple-select-label">
                  Golongan Obat
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="label_id"
                  id="demo-simple-select"
                  label="Golongan Obat"
                  value={formik.values.label_id}
                  disabled={nieNumber === ""}
                  onChange={(e) =>
                    formik.setFieldValue("label_id", e.target.value)
                  }
                >
                  <MenuItem
                    value=""
                    disabled
                    sx={{ display: "none" }}
                  ></MenuItem>
                  {groups !== undefined ? (
                    groups &&
                    groups.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      value=""
                      disabled
                      sx={{ display: "none" }}
                    ></MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                sx={{ marginTop: 3 }}
                id="outlined-basic"
                label="Perlu resep"
                variant="filled"
                disabled
                value={formik.values.require_prescription ? `Ya` : `Tidak`}
                onChange={(e) =>
                  formik.setFieldValue("require_prescription", e.target.value)
                }
              />
              <Autocomplete
                fullWidth
                sx={{ marginTop: 3 }}
                loading={isPrincipalLoading}
                disabled={nieNumber === ""}
                onOpen={() => setOpenPrincipal(true)}
                onClose={() => setOpenPrincipal(false)}
                disablePortal
                options={optionPrincipal}
                value={principalValue}
                onChange={(_, inputValue) => {
                  setPrincipalValue(inputValue);
                  formik.setFieldValue("principal_id", inputValue?.id);
                }}
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearchPrincipal(onInputValue);
                  }
                }}
                // getOptionLabel={(option) => (option ? option.name : "")}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                id="combo-box-demo"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Principal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isPrincipalLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <div className="flex flex-row gap-5">
                <div className="flex flex-col">
                  <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                    Kemasan
                  </h1>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="premier"
                      label="Qty"
                      variant="outlined"
                      className="mt-3"
                      disabled
                      value={formik.values.packaging.total_box}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_box",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Primer</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Primer"
                        disabled={nieNumber === ""}
                        value={formik.values.packaging.type_box.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_box",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 1 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_box.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_box.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_box.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 1;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="sekunder"
                      label="Qty"
                      variant="outlined"
                      disabled={nieNumber === ""}
                      className="mt-3"
                      value={formik.values.packaging.total_strip}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_strip",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Sekunder</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sekunder"
                        disabled={nieNumber === ""}
                        value={formik.values.packaging.type_strip.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_strip",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 2 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_strip.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_strip.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_strip.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 2;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase(0)}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="outlined-basic"
                      label="Qty"
                      variant="outlined"
                      className="mt-3"
                      disabled={nieNumber === ""}
                      value={formik.values.packaging.total_unit}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_unit",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-label">
                        Tersier
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={nieNumber === ""}
                        label="Tersier"
                        value={formik.values.packaging.type_unit.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_unit",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 3 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_unit.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_unit.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_unit.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 3;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                    Stock
                  </h1>
                  <div className="grid grid-cols-2 gap-4 items-center">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <button
                        className="bg-[#FF7A5D] p-3 mr-[10px] rounded-lg disabled:bg-gray-500"
                        disabled={nieNumber === "" || qtyType === ""}
                        type="button"
                        onClick={() => {
                          if (count === 0) return;
                          setCount((previousCount) => previousCount - 1);
                        }}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="border rounded-lg w-[69px] p-3"
                        disabled={nieNumber === "" || qtyType === ""}
                        value={count}
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          setCount(
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                          );
                        }}
                      />
                      <button
                        className="bg-[#5DFFBB] p-3 ml-[10px] rounded-lg disabled:bg-gray-500"
                        type="button"
                        disabled={nieNumber === "" || qtyType === ""}
                        onClick={() => {
                          setCount((previousCount) => previousCount + 1);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-label">
                        Tipe Unit
                      </InputLabel>
                      <Select
                        label="Tipe Unit"
                        value={qtyType}
                        disabled={
                          nieNumber === "" ||
                          formik.values.packaging.type_box === "" ||
                          formik.values.packaging.type_strip === "" ||
                          formik.values.packaging.type_unit === ""
                        }
                        onChange={(e) => setQtyType(e.target.value)}
                      >
                        <MenuItem
                          value={formik.values.packaging.type_box}
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                formik.values.packaging.total_unit
                              )
                            ) {
                              setQuantity(
                                count *
                                  formik.values.packaging.total_strip *
                                  formik.values.packaging.total_unit
                              );
                            } else {
                              setQuantity(
                                count * formik.values.packaging.total_unit
                              );
                            }
                          }}
                          sx={{
                            display:
                              formik.values.packaging.type_box === ""
                                ? "none"
                                : null,
                          }}
                        >
                          {formik.values.packaging.type_box}
                        </MenuItem>
                        <MenuItem
                          value={formik.values.packaging.type_strip}
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                formik.values.packaging.total_unit
                              )
                            ) {
                              setQuantity(count);
                            } else {
                              setQuantity(
                                count * formik.values.packaging.total_unit
                              );
                            }
                          }}
                          disabled={formik.values.packaging.type_strip === ""}
                          sx={{
                            display:
                              formik.values.packaging.type_strip === ""
                                ? "none"
                                : null,
                          }}
                        >
                          {formik.values.packaging.type_strip}
                        </MenuItem>
                        {IGNORE_TYPE.includes(
                          formik.values.packaging.type_unit
                        ) ? (
                          <MenuItem
                            value={0}
                            sx={{ display: "none" }}
                          ></MenuItem>
                        ) : (
                          <MenuItem
                            value={formik.values.packaging.type_unit}
                            onClick={() => {
                              setQuantity(count);
                            }}
                            disabled={formik.values.packaging.type_unit === ""}
                            sx={{
                              display:
                                formik.values.packaging.type_unit === ""
                                  ? "none"
                                  : null,
                            }}
                          >
                            {formik.values.packaging.type_unit}
                          </MenuItem>
                        )}
                        <MenuItem value={0} sx={{ display: "none" }}></MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <p className="mt-2 text-red-500 flex">
                    <span className="bg-red-500 rounded-full text-white px-[10px] text-sm py-[3px] font-bold">
                      i
                    </span>
                    &nbsp; Stock Akhir&nbsp;
                    <p>{finalStock}&nbsp;</p>
                    {IGNORE_TYPE.includes(formik.values.packaging.type_unit) ? (
                      <p>
                        {formik.values.packaging.type_strip.replace(/\d/g, "")}
                      </p>
                    ) : (
                      <p>
                        {formik.values.packaging.type_unit.replace(/\d/g, "")}
                      </p>
                    )}
                  </p>
                </div>
              </div>
              {formik.values.batch.map((b, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-evenly mt-4">
                    <TextField
                      sx={{ width: "40%" }}
                      id="outlined-basic"
                      disabled={nieNumber === ""}
                      label="Nomor Batch"
                      variant="outlined"
                      value={b.number_batch}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "batch",
                          formik.values.batch.map((b1, idx) => {
                            return index === idx
                              ? { ...b, number_batch: e.target.value }
                              : b1;
                          })
                        )
                      }
                    />
                    <TextField
                      sx={{ width: "30%", marginX: 3 }}
                      id="outlined-basic"
                      label="Kuantitas"
                      variant="outlined"
                      disabled={nieNumber === ""}
                      value={b.quantity}
                      onChange={(e) => {
                        //calculateBatchStock(e, index, b);
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "batch",
                          formik.values.batch.map((b1, idx) => {
                            return index === idx
                              ? {
                                  ...b,
                                  quantity: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ),
                                }
                              : b1;
                          })
                        );
                      }}
                    />
                    <TextField
                      disabled={nieNumber === ""}
                      label="Tanggal Expired"
                      fullWidth
                      onClick={() => {
                        setOpenSingleCal(true);
                        setSelectedBatch(index);
                      }}
                      value={
                        b.expired_date === ""
                          ? ""
                          : moment(b.expired_date).format("DD-MM-YYYY")
                      }
                    />
                    <button
                      type="button"
                      className="ml-[10px]"
                      disabled={nieNumber === ""}
                      onClick={() => {
                        if (formik.values.batch.length === 1) {
                          formik.setFieldValue("batch", []);
                        } else {
                          formik.setFieldValue("batch", [
                            ...formik.values.batch.slice(0, index),
                            ...formik.values.batch.slice(index + 1),
                          ]);
                        }
                      }}
                    >
                      <DeleteIcon sx={{ color: "black" }} />
                    </button>
                    {formik.values.batch.reduce((acc, num) => {
                      return acc + num.quantity;
                    }, 0) > count && formik.values.batch.length > count ? (
                      <div className="flex">
                        <p className="text-red-500">
                          Stock melebihi batas maksimal
                        </p>
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              ))}
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                onClick={() => {
                  if (
                    formik.values.batch.length === count ||
                    nieNumber === "" ||
                    formik.values.batch.reduce((acc, num) => {
                      return acc + num.quantity;
                    }, 0) >= count
                  ) {
                    return;
                  }
                  formik.setFieldValue("batch", [
                    ...formik.values.batch,
                    { quantity: 0, number_batch: "", expired_date: "" },
                  ]);
                }}
              >
                +Tambah Batch lain
              </p>
              <TextField
                id="barcode"
                fullWidth
                variant="outlined"
                disabled={nieNumber === ""}
                label="Nomor Barcode"
                value={formik.values.barcode_number}
                onChange={(e) =>
                  formik.setFieldValue("barcode_number", e.target.value)
                }
                sx={{ marginTop: 3 }}
              />
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">HNA</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="HNA"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={formik.values.price.pharmacy_net_price_real}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    formik.setFieldValue(
                      "price.pharmacy_net_price_real",
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Discount</p>
                <TextField
                  id="outlined-basic"
                  label="Discount"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={
                    ({
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    },
                    {
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    })
                  }
                  value={formik.values.price.pharmacy_net_price_discount}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    if (
                      Number(numericValue) >= 0 &&
                      Number(numericValue) <= 100
                    )
                      formik.setFieldValue(
                        "price.pharmacy_net_price_discount",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                  }}
                />
              </div>

              <div className="grid grid-cols-2 mt-4 items-center">
                <p className="font-bold">Harga Akhir HNA</p>
                <TextField
                  id="outlined-basic"
                  label="Final Price"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={pharmacyNetPriceFinal(
                    formik.values.price.pharmacy_net_price_discount,
                    formik.values.price.pharmacy_net_price_real,
                    formik.values.price.pharmacy_net_price_ppn
                  )}
                />
              </div>
              <div className="flex justify-between items-center mt-4">
                <p className="font-bold">PPN 11%</p>
                <Switch
                  checked={formik.values.price.pharmacy_net_price_ppn}
                  disabled={nieNumber === ""}
                  onClick={() => {
                    formik.setFieldValue(
                      "price.pharmacy_net_price_ppn",
                      !formik.values.price.pharmacy_net_price_ppn
                    );
                  }}
                />
              </div>
              {isWarehouse ? null : (
                <>
                  <div className="grid grid-cols-2 justify-between mt-4 items-center">
                    <p className="font-bold">Harga Jual Primer</p>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Harga Jual Primer"
                      disabled={nieNumber === ""}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                      value={formik.values.price.sell_price}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "price.sell_price",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                        formik.setFieldValue(
                          "price.sell_price_sekunder",
                          Math.round(
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10) /
                              formik.values.packaging.total_strip
                          )
                        );
                        formik.setFieldValue(
                          "price.sell_price_tersier",
                          Math.round(
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10) /
                              formik.values.packaging.total_strip /
                              formik.values.packaging.total_unit
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 justify-between mt-4 items-center">
                    <p className="font-bold">Harga Jual Sekunder</p>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Harga Jual Sekunder"
                      disabled={nieNumber === ""}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                      value={formik.values.price.sell_price_sekunder}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "price.sell_price_sekunder",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 justify-between mt-4 items-center">
                    <p className="font-bold">Harga Jual Tersier</p>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Harga Jual Tersier"
                      disabled={nieNumber === ""}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                      value={formik.values.price.sell_price_tersier}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "price.sell_price_tersier",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                  </div>
                </>
              )}
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Rak (optional)</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Shelf"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  value={formik.values.shelf}
                  onChange={(e) => {
                    formik.setFieldValue("shelf", e.target.value);
                  }}
                />
              </div>

              {isWarehouse ? null : (
                <>
                  {" "}
                  {formik.values.price.price_grosir.map((g, index) => {
                    return (
                      <div className="my-2 w-full" key={index}>
                        <div className="w-full flex gap-2 items-center">
                          {index > 0 ? (
                            <div className="w-full basis-1/3"></div>
                          ) : (
                            <p className="font-bold mt-2 w-full basis-1/3">
                              Harga Grosir
                            </p>
                          )}
                          <div className="w-full flex gap-2 items-center">
                            <TextField
                              fullWidth
                              value={g.minimum}
                              disabled={nieNumber === ""}
                              onChange={(e) => {
                                let numericValue = e.target.value;
                                if (numericValue === "") {
                                  numericValue = "0";
                                }
                                formik.setFieldValue(
                                  "price.price_grosir",
                                  formik.values.price.price_grosir.map(
                                    (g1, idx) => {
                                      return index === idx
                                        ? {
                                            ...g,
                                            minimum: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ),
                                          }
                                        : g1;
                                    }
                                  )
                                );
                              }}
                            />
                            <p>S/D</p>
                            <TextField
                              fullWidth
                              value={g.maximum}
                              disabled={nieNumber === ""}
                              onChange={(e) => {
                                let numericValue = e.target.value;
                                if (numericValue === "") {
                                  numericValue = "0";
                                }
                                formik.setFieldValue(
                                  "price.price_grosir",
                                  formik.values.price.price_grosir.map(
                                    (g1, idx) => {
                                      return index === idx
                                        ? {
                                            ...g,
                                            maximum: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ),
                                          }
                                        : g1;
                                    }
                                  )
                                );
                              }}
                            />
                          </div>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            disabled={nieNumber === ""}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Rp.
                                </InputAdornment>
                              ),
                            }}
                            value={g.price_grosirs}
                            onChange={(e) => {
                              if (nieNumber === "") {
                                return;
                              }

                              let numericValue = e.target.value;
                              if (numericValue === "") {
                                numericValue = "0";
                              }
                              formik.setFieldValue(
                                "price.price_grosir",
                                formik.values.price.price_grosir.map(
                                  (g1, idx) => {
                                    return index === idx
                                      ? {
                                          ...g,
                                          price_grosirs: parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        }
                                      : g1;
                                  }
                                )
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="ml-[10px]"
                            disabled={nieNumber === ""}
                            onClick={() => {
                              formik.setFieldValue("price.price_grosir", [
                                ...formik.values.price.price_grosir.slice(
                                  0,
                                  index
                                ),
                                ...formik.values.price.price_grosir.slice(
                                  index + 1
                                ),
                              ]);
                            }}
                          >
                            <DeleteIcon sx={{ color: "black" }} />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-right w-full"
                    onClick={() => {
                      if (nieNumber === "") {
                        return;
                      }
                      formik.setFieldValue("price.price_grosir", [
                        ...formik.values.price.price_grosir,
                        { minimum: 0, maximum: 0, price_grosirs: 0 },
                      ]);
                    }}
                  >
                    +Tambah harga grosir baru
                  </p>
                </>
              )}

              <div className="flex justify-between items-center mt-4">
                <p className="font-bold">Pengingat Produk Habis</p>
                <Switch
                  checked={formik.values.status_reminder_stock}
                  disabled={nieNumber === ""}
                  onClick={() => {
                    if (formik.values.status_reminder_stock)
                      formik.setFieldValue("reminder_stock", 5);
                    formik.setFieldValue(
                      "status_reminder_stock",
                      !formik.values.status_reminder_stock
                    );
                  }}
                />
              </div>
              {formik.values.status_reminder_stock ? (
                <div className="flex flex-row gap-2 items-center mt-4">
                  <p>Nyalakan Pengingat jika stok mencapai</p>
                  <div className="flex">
                    <button
                      className="bg-[#FF7A5D] p-3 mr-[10px] rounded-lg disabled:bg-gray-500"
                      disabled={nieNumber === ""}
                      type="button"
                      onClick={() => {
                        if (formik.values.reminder_stock === 0) return;
                        formik.setFieldValue(
                          "reminder_stock",
                          formik.values.reminder_stock - 1
                        );
                      }}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      className="border rounded-lg w-[69px] p-3"
                      disabled={nieNumber === ""}
                      value={formik.values.reminder_stock}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "reminder_stock",
                          Number(e.target.value)
                        )
                      }
                    />
                    <button
                      className="bg-[#5DFFBB] p-3 ml-[10px] rounded-lg disabled:bg-gray-500"
                      type="button"
                      disabled={nieNumber === ""}
                      onClick={() => {
                        formik.setFieldValue(
                          "reminder_stock",
                          formik.values.reminder_stock + 1
                        );
                      }}
                    >
                      +
                    </button>
                  </div>
                  <FormControl sx={{ marginLeft: 3, width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Pilihan Package
                    </InputLabel>
                    <Select
                      label="Pilihan Package"
                      value={formik.values.reminder_uom}
                      disabled={formik.values.reminder_stock === false}
                      onChange={(e) =>
                        formik.setFieldValue("reminder_uom", e.target.value)
                      }
                    >
                      <MenuItem value={0}>Primer</MenuItem>
                      <MenuItem value={1}>Sekunder</MenuItem>
                      <MenuItem value={2}>Tersier</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ) : null}

              {isWarehouse ? null : (
                <div>
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                    onClick={handleAddPlatform}
                  >
                    +Tambah Platform lain
                  </p>
                  <div>
                    {platforms &&
                    platforms !== undefined &&
                    formik.values.price.platform_price.length > 0 ? (
                      <>
                        {formik.values.price.platform_price.map(
                          (plat, index) => {
                            return (
                              <div
                                className="flex justify-center items-center mt-[20px]"
                                key={plat.platform_id}
                              >
                                <FormControl fullWidth sx={{ marginRight: 1 }}>
                                  <InputLabel id="demo-simple-select-label">
                                    Kategori
                                  </InputLabel>
                                  <Select
                                    id="add-category"
                                    label="Kategori"
                                    value={plat.platform_id}
                                    disabled={nieNumber === ""}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "price.platform_price",
                                        formik.values.price.platform_price.map(
                                          (p1) => {
                                            return p1.platform_id ===
                                              plat.platform_id
                                              ? {
                                                  ...plat,
                                                  platform_id: e.target.value,
                                                }
                                              : p1;
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    {platforms.map((p) => {
                                      return (
                                        <MenuItem
                                          key={p.id}
                                          value={p.id}
                                          disabled={
                                            formik.values.price.platform_price.find(
                                              (f) => f.id === p.id
                                            )
                                              ? true
                                              : false
                                          }
                                          sx={{
                                            display:
                                              formik.values.price.platform_price.find(
                                                (f) => f.id === p.id
                                              )
                                                ? "none"
                                                : null,
                                          }}
                                        >
                                          {p.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Jumlah Uang
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    label="Jumlah Uang"
                                    type="text"
                                    onChange={(e) => {
                                      let numericValue = e.target.value;
                                      if (numericValue === "") {
                                        numericValue = "0";
                                      }

                                      formik.setFieldValue(
                                        "price.platform_price",
                                        formik.values.price.platform_price.map(
                                          (p1) => {
                                            return p1.platform_id ===
                                              plat.platform_id
                                              ? {
                                                  ...plat,
                                                  price: parseInt(
                                                    numericValue.replace(
                                                      /[^0-9]/g,
                                                      ""
                                                    ),
                                                    10
                                                  ),
                                                }
                                              : p1;
                                          }
                                        )
                                      );
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                          onClick={() => {
                                            if (
                                              formik.values.price.platform_price
                                                .length === 1
                                            )
                                              formik.setFieldValue(
                                                "price.platform_price",
                                                []
                                              );
                                            else
                                              formik.setFieldValue(
                                                "price.platform_price",
                                                [
                                                  ...formik.values.price.platform_price.slice(
                                                    0,
                                                    index
                                                  ),
                                                  ...formik.values.price.platform_price.slice(
                                                    index + 1
                                                  ),
                                                ]
                                              );
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          ) : isFetching ? (
            <div className="w-full flex justify-center align-center my-4">
              <CircularProgress size={20} />
            </div>
          ) : (
            <p className="w-full text-red-500 text-center my-4">
              Terjadi kesalahan, mohon coba lagi
            </p>
          )}

          <div className="grid grid-cols-2 mt-10 gap-12">
            <button
              className="bg-red-700 text-white font-semibold p-3 rounded-lg"
              type="button"
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-aktif`
                )
              }
            >
              Batal
            </button>
            <button
              className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-500"
              type="submit"
              disabled={
                formik.values.batch.reduce((acc, num) => {
                  return acc + num.quantity;
                }, 0) === 0 ||
                formik.values.batch.reduce((acc, num) => {
                  return acc + num.quantity;
                }, 0) !== count ||
                count === 0 ||
                qtyType === ""
              }
            >
              Konfirmasi
            </button>
          </div>
        </div>
      </form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className="flex justify-center">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="mr-[10px]"
          >
            <ClearIcon sx={{ color: "black" }} />
          </button>
          <TextField
            fullWidth
            focused
            label="Nomor Izin Edar"
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ height: 500 }}>
          {isNieLoading ? (
            <div className="flex items-center justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <>
              {nie && nie !== undefined && nie.length > 0
                ? nie.map((data) => (
                    <div
                      className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer"
                      key={data.id}
                      onClick={() => {
                        setNieId(data.id);
                        setBpomId(data.product_bpom_id);
                        // refetchDetail(data.id, data.product_bpom_id);
                        setNieNumber(data.nie_number);
                        setOpen(false);
                        setIsCreateProductWithNIE(true);
                      }}
                    >
                      <p className="font-bold">{data.nie_number}</p>
                      <p className="font-bold">{data.product_name}</p>
                      <p className="font-bold">{data.kemasan}</p>
                    </div>
                  ))
                : null}
              {nie.length === 0 ? (
                <div
                  className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer text-bold"
                  onClick={() => {
                    setOpen(false);
                    setIsCreateProductWithNIE(false);
                    setNieNumber(search);
                  }}
                >
                  <p className="font-bold">{search}</p>
                </div>
              ) : null}
            </>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        labelId="demo-simple-select-label"
        name="label_id"
        id="demo-simple-select"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <PopupSingleCalendar
        disabled={nieNumber === ""}
        open={openSingleCal}
        setOpen={setOpenSingleCal}
        handleClose={() => {
          setSelectedBatch(-1);
          setOpenSingleCal(false);
        }}
        value={
          selectedBatch >= 0
            ? formik.values.batch[selectedBatch].expired_date !== ""
              ? formik.values.batch[selectedBatch].expired_date
              : null
            : null
        }
        handleChange={(e) => {
          formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return selectedBatch === idx
                ? {
                    ...formik.values.batch[selectedBatch],
                    expired_date: toDateApi(e),
                  }
                : b1;
            })
          );
        }}
        disablePast={true}
      />

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default AddProduct;
